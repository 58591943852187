<template>
  <b-row>
    <b-col xl="12">
      <b-card>
        <b-card-title class="font-medium-2" style="opacity: 0.8"
        >Password</b-card-title
        >
        <!-- form -->
        <ValidationObserver ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="updateUserPassword(userSelect._id)"
          >
            <!-- password -->
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              :rules="whitelabelRule"
            >
              <b-form-group
                :label="$t('user-list.labels.newPassword')"
                label-for="reset-password-new"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility()"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <!--                <small v-if="validFormatPassword" class="text-danger">{{ validFormatPassword }}</small>-->
              </b-form-group>
            </validation-provider>
            <!-- confirm password -->
            <validation-provider
              #default="{ errors }"
              name="ConfirmPassword"
              rules="required|confirmed:Password"
            >
              <b-form-group
                label-for="reset-password-confirm"
                :label="$t('user-list.labels.confirmPassword')"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility()"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <!-- submit button -->
            <b-button type="submit" variant="primary">
              <b-spinner v-if="isLoading" small></b-spinner>
              <span v-else>{{ $t("user-list.buttons.setNewPassword") }}</span>
            </b-button>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BCardTitle,
  BCardText,
  BLink,
} from "bootstrap-vue";
import { translatableText, whitelabelPassRules } from "@core/utils/utils";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@core/utils/validations/validations";
import { getUserData } from "@/auth/utils";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import userStoreModule from "@/views/apps/user/userStoreModule";
import { onUnmounted } from "@vue/composition-api";

export default {
  components: {
    BModal,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },
  data() {
    return {
      isLoading: false,
      idUserSelect: "",
      userSelect: null,

      userEmail: "",
      cPassword: "",
      password: "",
      // validation
      required,

      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    validFormatPassword() {
      let regex =
        /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;
      if (!regex.test(this.password) && this.password.length >= 8)
        return this.$t("labels.password_format");
      else return false;
    },
    whitelabelRule() {
      const whitelabelSelected =
        this.$store.state.whitelabelCurrencyNabvar.whitelabel;
      return whitelabelPassRules(whitelabelSelected);
    },
  },
  mounted() {
    this.idUserSelect = getUserData();
    this.userSelect = { _id: this.idUserSelect._id };
  },
  methods: {
    clearForm() {
      this.password1FieldType = "password";
      this.password2FieldType = "password";
      this.cPassword = "";
      this.password = "";
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    async updateUserPassword() {
      try {
        this.$refs.simpleRules.validate().then(async (success) => {
          if (success) {
            this.isLoading = true;
            await store.dispatch("app-user/updateUserPassword", {
              id: this.userSelect._id,
              password: this.password,
              confirmPassword: this.cPassword,
            });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("user-list.messages.passwordUpdated"),
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.clearForm();
            this.isLoading = false;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("user-list.messages.fieldsError"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
          }
        });
      } catch (error) {
        this.isLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Password",
            icon: "LockIcon",
            text: await translatableText({ text: axiosErrorHandle(e) }),
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
