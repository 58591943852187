<template>
  <b-tabs
    v-if="userData"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <!--    <b-tab active>-->
    <!--      &lt;!&ndash; title &ndash;&gt;-->
    <!--      <template #title>-->
    <!--        <feather-icon icon="UserIcon" size="18" class="mr-50" />-->
    <!--        <span class="font-weight-bold">General</span>-->
    <!--      </template>-->

    <!--      <account-setting-general v-if="userData" :general-data="userData" />-->
    <!--    </b-tab>-->
    <!--/ general tab -->

    <!-- change password tab -->
    <!--    <b-tab>-->
    <!--      &lt;!&ndash; title &ndash;&gt;-->
    <!--      <template #title>-->
    <!--        <feather-icon icon="LockIcon" size="18" class="mr-50" />-->
    <!--        <span class="font-weight-bold">Change Password</span>-->
    <!--      </template>-->

    <!--      &lt;!&ndash; <account-setting-password /> &ndash;&gt;-->
    <!--    </b-tab>-->
    <!--/ change password tab -->

    <!-- info -->
    <!--    <b-tab>-->
    <!--      &lt;!&ndash; title &ndash;&gt;-->
    <!--      <template #title>-->
    <!--        <feather-icon icon="InfoIcon" size="18" class="mr-50" />-->
    <!--        <span class="font-weight-bold">Information</span>-->
    <!--      </template>-->

    <!--      &lt;!&ndash; <account-setting-information-->
    <!--        v-if="options.info"-->
    <!--        :information-data="options.info"-->
    <!--      /> &ndash;&gt;-->
    <!--    </b-tab>-->

    <!-- social links -->
    <!--    <b-tab>-->
    <!--      &lt;!&ndash; title &ndash;&gt;-->
    <!--      <template #title>-->
    <!--        <feather-icon icon="LinkIcon" size="18" class="mr-50" />-->
    <!--        <span class="font-weight-bold">Social</span>-->
    <!--      </template>-->

    <!--      &lt;!&ndash; <account-setting-social-->
    <!--        v-if="options.social"-->
    <!--        :social-data="options.social"-->
    <!--      /> &ndash;&gt;-->
    <!--    </b-tab>-->

    <!-- notification -->
    <!--    <b-tab>-->
    <!--      &lt;!&ndash; title &ndash;&gt;-->
    <!--      <template #title>-->
    <!--        <feather-icon icon="BellIcon" size="18" class="mr-50" />-->
    <!--        <span class="font-weight-bold">Notifications</span>-->
    <!--      </template>-->

    <!--      &lt;!&ndash; <account-setting-notification-->
    <!--        v-if="options.notification"-->
    <!--        :notification-data="options.notification"-->
    <!--      /> &ndash;&gt;-->
    <!--    </b-tab>-->

    <!--      Security-->
    <b-tab activate="">
      <!-- title -->
      <template #title>
        <div class="w-200p">
          <feather-icon icon="LockIcon" size="18" class="" />
          <span class="font-weight-bold">{{ $t("security") }}</span>
        </div>
      </template>

      <account-setting-security v-if="userData" />
      <account-setting-password/>
    </b-tab>
  </b-tabs>
  <div
    v-else
    style="height: 70vh"
    class="d-flex justify-content-center align-items-center"
  >
    <b-spinner></b-spinner>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingInformation from "./AccountSettingInformation.vue";
import AccountSettingSocial from "./AccountSettingSocial.vue";
import AccountSettingNotification from "./AccountSettingNotification.vue";
import AccountSettingSecurity from "./AccountSettingSecurity.vue";
import axios from "@/libs/axios";

export default {
  components: {
    AccountSettingSecurity,
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
      userData: null,
    };
  },
  methods: {
    fetchUser({ id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    try {
      const { data } = await this.fetchUser({ id: userData._id });
      this.userData = data;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
<style>
ul[role="tablist"] > li[role="presentation"] {
  width: 100%;
}
</style>
